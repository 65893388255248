import trackingRepository from './trackingRepository'

const repositories = {
  tracking: trackingRepository,
}

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: name => repositories[name],
}
