<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <img
        v-if="!loading"
        class="w-100 pb-1"
        style="height:60px;width:60px;object-fit:cover;"
        :src="business.company_logo ? `${bucketUrl}${business.company_logo}` : defaultCompanyLogo"
        alt="image slot"
      >
      <div
        v-if="loading"
        class="spinner-border"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <h2 class="brand-text text-primary ml-1 mt-1">
        <div
          v-if="loading"
          class="spinner-border"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <div v-if="!loading">
          {{ business.company_name }}
        </div>
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import { bucketUrl, defaultCompanyLogo } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'

const trackingRepository = RepositoryFactory.get('tracking')

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      bucketUrl,
      defaultCompanyLogo,
      downImg: require('@/assets/images/pages/error.svg'),
      business: {},
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    this.getBusinessInfo()
  },
  methods: {
    async getBusinessInfo() {
      try {
        this.loading = true
        this.business = localStorage.getItem('businessSettings') ? JSON.parse(localStorage.getItem('businessSettings')) : (await trackingRepository.getBusinessInfo()).data.data
        localStorage.setItem('businessSettings', JSON.stringify(this.business))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
