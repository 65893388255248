import Repository from '@/libs/axios'

const resource = '/public/order'

const trackingSettingsLogoResource = '/settings/tracking'

export default {
  getOrderTrackingInfo(payload) {
    return Repository.post(`${resource}/tracking-info`, payload)
  },
  getBusinessInfo() {
    return Repository.get('public/business')
  },
  getTrackingPublicSettingsResource() {
    return Repository.get(`${trackingSettingsLogoResource}`)
  },
}
